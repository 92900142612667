import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: any;
}

function PageContent({ children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
    content: {
      paddingTop: theme.height.navigation + theme.spacing.unit * 4,
      maxWidth: theme.width.page,
      width: '100%',
      '& > div': {
        marginBottom: theme.spacing.sectionBreak,
        [theme.media.xs]: {
          marginBottom: theme.spacing.sectionBreakMobile,
        },
      },
    },
  };
});

export default PageContent;
