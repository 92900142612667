import React from 'react';
import classNames from 'clsx';
import { createUseStyles } from 'react-jss';

function ScenicBackground() {
  const classes = useStyles();
  return <div className={classNames(classes.root, 'bg-animation')}></div>;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      height: 150,
      [theme.media.sm]: {
        height: 115,
      },
    },
  };
});

export default ScenicBackground;
