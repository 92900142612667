import React from 'react';
import Markdown from 'react-markdown/with-html';

// Components
import SEO from 'components/seo';
import PageContent from 'components/page-content';
import Layout from 'components/layout';
import ScenicBackground from 'components/scenic-background';

interface Props {
  pageContext: PageContext;
}

interface PageContext {
  markdown: string;
  title: string;
}

function PageTemplate({ pageContext }: Props) {
  const { title, markdown } = pageContext;

  return (
    <>
      <SEO title={title} />
      <Layout>
        <ScenicBackground />
        <PageContent>
          <Markdown source={markdown} escapeHtml={false} />
        </PageContent>
      </Layout>
    </>
  );
}

export default PageTemplate;
